.search{    
    width: 200px;
    margin: 12px;
    z-index: 20;
    position: absolute;
    right: 20px;
}

.search-input{
    width: 100%;
    height: 30px;
}


.data-result {
    margin-top: 10px;
    width: 230px;
    height: 400px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
  }
  
  .data-result::-webkit-scrollbar {
    display: none;
  }
  
  .data-result .data-item {
    width: 100%;
    height: 30px;
    display: flex;
    margin: 10px;
    align-items: center;
    color: black;
  }  

  .component-link{
    text-decoration: none !important;
    color: black;
  }