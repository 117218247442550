.rank-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.rank-container-boxes {
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  background: none;
  padding: 30px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9e9e9e;
  font-size: 24px;
  margin: 0px;
  outline: none;
}
.rank-container-boxes:hover {
  border: 1px solid #016e81;
  color: #016e81;
}
.rank-container-boxes:focus {
  border: 2px solid #016e81;
  color: #016e81;
}

.rank-container-boxes-afterClick {
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  background: none;
  padding: 30px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin: 0px;
  border: 2px solid #016e81;
  color: #016e81;
  outline: none;
}

.search-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input-area {
  width: 74%;
  outline: none;
  padding: 10px;
  border: 1px solid #d2d2d2;
  height: 40px;
}
.search-input-area::placeholder {
  color: #d2d2d2;
}

.new-scheme-button {
  width: 24%;
  background-color: #fc9051;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  font-size: 20px;
}

.newsheme-container {
  border: 1px solid #d2d2d2;
  /* height: 300px; */
  margin-top: 20px;
  padding: 30px;
}

.newshceme-form-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding-top: 20px; */
  /* padding-right: 20px; */
}

.newshceme-form-buttonOne {
  background-color: #fc9051;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  font-size: 18px;
  width: 148px;
}

.newshceme-form-buttonTwo {
  background: none;
  color: #fc9051;
  border: none;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  font-size: 18px;
  width: 95px;
  margin-left: 20px;
  border: 1px solid #fc9051;
}

.newscheme-date-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 80%; */
}
.newscheme-date-container > p {
  font-size: 20px;
  margin-left: 30px;
}

.newscheme-date-container-input {
  /* width: 100%; */
  display: flex;
}

.newscheme-date-container-input-date {
  width: 360px;
  height: 40px;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  padding: 10px;
  outline: none;
  font-size: 18px;
  margin: 0 20px;
}

.newscheme-location-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 0 20px;
}

.newscheme-location-container > p {
  font-size: 20px;
  margin-left: 10px;
}

.newscheme-location-container > Select {
  width: 50%;
}

.basic-multi-select {
  width: 760px;
  margin-left: 30px;
}

.basic-multi-select-state {
  width: 760px;
  margin-left: 30px;
}

.rank-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.rank-add-button {
  background-color: #fc9051;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
  width: 170px;
}

.rank-delete-button {
  background: none;
  color: #fc9051;
  border: 1px solid #fc9051;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
}

.view-schemes-container-heading {
  margin-top: 35px;
  border: 1px solid #d2d2d2;
  padding: 20px;
  font-size: 20px;
}

.view-schemes-container {
  padding: 30px;
  font-size: 18px;
}

.scheme-edit-submit-button {
  background-color: #fc9051;
  color: #fff;
  border: none;
  border-radius: 2px;
  padding: 1px;
  cursor: pointer;
  font-size: 14px;
  width: 60px;
  /* margin-left: 10px; */
}

.scheme-edit-button {
  background: none;
  color: #fc9051;
  border: 1px solid #fc9051;
  border-radius: 2px;
  padding: 1px;
  cursor: pointer;
  font-size: 14px;
  width: 60px;
}

.delete-button-menu {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  color: #d2d2d2;
}
.delete-button-menu:hover {
  color: #cc0000;
}

.delete-button-area {
  position: absolute;
  right: -130px;
  top: 60px;
  z-index: 5;
  width: 191px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #9e9e9e;
}

.delete-button-menu-second {
  background: none;
  border: none;
  width: 100%;
  cursor: pointer;
  padding: 10px;
}
.delete-button-menu-second:hover {
  background-color: #fc9051;
  border: none;
  width: 100%;
  color: #fff;
  padding: 10px;
}
