.kycCard_subRow1{
    display: grid;
    grid-template-columns: 5fr 1fr;
}
.kycCard_bankDetails{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.kycCard_bankDetails > *{
    margin: 10px 0px;
}
.kycCard_bankDetails > input{
    padding: 10px;
    height: 30px;
     margin: auto 0px;
}