.kyclist-header {
  margin-top: 50px;
  margin-bottom: 30px;
  /* align-items: center; */
}

.sort-select {
  outline: none;
  background: none;
  border: 1px solid #dfdfdf;
  color: #36413e;
  padding: 4px;
  width: 150px;
  height: 35px;
  border-radius: 5px;
  position: relative;
  left: 800px;
}

.sort-button-newest {
  outline: none;
  background: none;
  border: 1px solid #dfdfdf;
  color: #36413e;
  padding: 4px;
  width: 106px;
  height: 35px;
  border-radius: 5px;
  position: relative;
  left: 740px;
}

.sort-button-oldest {
  outline: none;
  background: none;
  border: 1px solid #dfdfdf;
  color: #36413e;
  padding: 4px;
  width: 106px;
  height: 35px;
  border-radius: 5px;
  position: relative;
  left: 770px;
}

.filter-button {
  outline: none;
  background: none;
  border: 1px solid #dfdfdf;
  color: #36413e;
  padding: 4px;
  width: 106px;
  height: 35px;
  border-radius: 5px;
  position: relative;
  left: 800px;
}

.filter-button:hover {
  background: #fc9051;
  border: none;
  color: #fff;
}
.sort-button-newest:hover {
  background: #fc9051;
  border: none;
  color: #fff;
}
.sort-button-oldest:hover {
  background: #fc9051;
  border: none;
  color: #fff;
}

.filter-box {
  width: 30%;
  position: relative;
  left: 400px;
  margin-bottom: 30px;
  margin-top: 40px;
}

.filter-box p {
  font-size: 18px;
}

.filter-box-age {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.filter-box-age-select {
  width: 72px;
  color: #9e9e9e;
  padding: 3px;
  border-color: #9e9e9e;
  border-radius: 3px;
}

.filter-box-location {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.basic-multi-select {
  width: 50%;
}

.filter-box-status-choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-box-checkbox {
  height: 17px;
  width: 17px;
  border: 1px solid #9e9e9e;
}

.filter-box-apply-button {
  width: 40%;
  height: 45px;
  margin: 10px;
  border-radius: 8px;
  border: none;
  background: #fc9051;
  outline: none;
  font-size: 20px;
  color: #fff;
}

.filter-box-cancel-button {
  width: 40%;
  height: 45px;
  margin: 10px;
  border-radius: 8px;
  border: none;
  background: #fc9051;
  outline: none;
  font-size: 20px;
  color: #fff;
}

.reject-button-container {
  margin-top: 5px;
  float: right;
}

.reject-button {
  width: 70px;
  height: 23px;
  border-radius: 3px;
  border: none;
  background: #fc9051;
  outline: none;
  font-size: 11px;
  color: #fff;
}

.reject-button:hover {
  background: #ff0000;
  border: none;
  color: #fff;
}
.kycList__statusButton {
  background: inherit;
  border: none;
}
