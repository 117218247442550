.til_limitSelect{
    margin-top: 125px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: 30%;
    height: fit-content;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
}
.til_limitSelect > select{
    border: 1px solid #ccc;
    padding: 6px;
    border-radius: 3px;
}