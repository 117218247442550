.limitSelectDiv{
    display: flex;
    margin-top: 55px;
    justify-content: space-around;
    width: 30%;
    margin-left: auto;
    margin-right: auto;
}
.limitSelectDiv > select{
    border: 1px solid #ccc;
    border-radius: 6px;
}